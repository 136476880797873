import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src="/logo192-removebg-preview.png" alt="Your Logo" />
        </div>
        <div className="contact-me">
          <p style={{ textAlign:'center',padding:'10px',fontWeight:'bold' }}>Contact Me</p>
          <div className="social-icons">
            <a href="https://twitter.com/jerryisaac7" target="_blank" rel="noopener noreferrer">
              <img
                src="https://d1sax8z0tfd9ab.cloudfront.net/icons/twitter.svg"
                alt="Twitter"
              />
            </a>
            <a href="https://facebook.com/jerry.isaac.9469" target="_blank" rel="noopener noreferrer">
              <img
                src="https://d1sax8z0tfd9ab.cloudfront.net/icons/facebook.svg"
                alt="Facebook"
              />
            </a>
            <a href="https://github.com/2402199" target="_blank" rel="noopener noreferrer">
              <img style={{ width: '30px', height: '30px' }}
                src="https://d1sax8z0tfd9ab.cloudfront.net/icons/footer-git.png"
                alt="GitHub"
              />
            </a>
            <a href="https://in.linkedin.com/in/jerry-isaac-b97a42258" target="_blank" rel="noopener noreferrer">
              <img
                src="https://d1sax8z0tfd9ab.cloudfront.net/icons/linkedin.svg"
                alt="LinkedIn"
              />
            </a>
            <a href="mailto:jerry24isaac@gmail.com">
              <img
                src="https://d1sax8z0tfd9ab.cloudfront.net/icons/mail.svg"
                alt="Email"
              />
            </a>
          </div>
          <p style={{ paddingTop: '10px', fontSize: '14px', textAlign: 'center' }}>Phone: +91 7395917495</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
