import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { scrollToContact, scrollToExperience, scrollToprojects, scrollToHome, scrollToSkills } from "./scrollUtils";

export const handleBlogLinkClick = () => {
  window.location.href = "https://blog.jerrycloud.in";
};

const handleDownload = () => {
  const url = "https://d1sax8z0tfd9ab.cloudfront.net/Resume/Jerry-Paul-Isaac-2024_resume+(1).pdf";
  const filename = "resume.pdf";

  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export default function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = (link) => {
    if (link === "home") {
      scrollToHome();
    }
    if (link === "contact") {
      scrollToContact();
    }
    if (link === "experience") {
      scrollToExperience();
    }
    if (link === "projects") {
      scrollToprojects();
    }
    if (link === "skills") {
      scrollToSkills();
    }

    setMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <Link onClick={() => handleLinkClick("home")}>
          <img src="/logo192-removebg-preview.png" alt="Logo" />
        </Link>
      </div>
      <div className={`menu ${menuOpen ? "active" : ""}`}>
        <ul>
        <li>
            <a href="#" onClick={handleDownload}>
              Resume
            </a>
          </li>
          <li>
            <Link onClick={() => handleLinkClick("experience")}>Experience</Link>
          </li>
          <li>
            <a
              href="https://blog.jerrycloud.in"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick("blog")}
            >
              Blogs
            </a>
          </li>
          <li>
            <Link onClick={() => handleLinkClick("projects")}>Projects</Link>
          </li>
          <li>
            <Link onClick={() => handleLinkClick("skills")}>Skills</Link>
          </li>
          <li>
            <Link onClick={() => handleLinkClick("contact")}>Contact</Link>
          </li>

        </ul>
      </div>
      <div className="menu-toggle" onClick={handleMenuToggle}>
        {menuOpen ? (
          <img
            src="https://d1sax8z0tfd9ab.cloudfront.net/icons/circle.png"
            alt="Close Icon"
          />
        ) : (
          <img
            src="https://d1sax8z0tfd9ab.cloudfront.net/icons/lines.png"
            alt="Hamburger Icon"
          />
        )}
      </div>
    </nav>
  );
}
