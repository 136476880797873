import Home from "./pages/Home";
import {  Routes, Route } from "react-router-dom";
import Experience from "./pages/Experience";
import Contact from "./pages/Contact";
// import Portfolio from "./pages/Portfolio";
import Skills from "./pages/Skills";
import './index.css'
import Projects from "./pages/Projects";

function App()  {
  return (
  <>
  {/* <Navbar/> */}
  {/* <div className="container"> */}
    <Routes>
      <Route path = "/" element={<Home />}/>
      <Route path = "/resume" element={<Experience />}/>
      <Route path = "/blog" element={<Experience/>}/>
      <Route path="/contact" element = {<Contact/>}/>
      <Route path="/projects" element = {<Projects/>}/>
      <Route path="/skills" element = {<Skills/>}/>
    </Routes>
  {/* </div> */}
  </>
  )
  
  
};

export default App;
