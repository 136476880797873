import React, { useRef } from "react";
import "./Home.css";
import { handleBlogLinkClick } from "../component/Navbar";
import Experience from "./Experience";
import ContactForm from "./Contact";
import Skills from "./Skills";
import Projects from "./Projects";

const isMobileScreen = window.innerWidth <= 768;

export default function Home() {
  const contactRef = useRef(null);
  const experienceRef = useRef(null);
  const skillsRef = useRef(null);
  const homeRef = useRef(null);

  return (
    <>
      <div id="home-section" ref={homeRef}>
        <div className="row">
          <div className="image">
            <img
              src="https://d1sax8z0tfd9ab.cloudfront.net/bghome.jpg"
              alt=""
            />
            <div className="overlay">
              <h1>
                Hello
                <br />
                I am Jerry Isaac,
                <p className="font">DevOps Engineer</p>
              </h1>
            </div>
          </div>
        </div>
        <div className="containe">
          <div className="side">
            <div className="icons">
              <img
                src="https://d1sax8z0tfd9ab.cloudfront.net/icons/illustration.png"
                alt=""
              />
            </div>
            <div className="blog">
              <button
                className="blog_button"
                type="button"
                onClick={handleBlogLinkClick}
              >
                My Blogs
              </button>
            </div>
          </div>
          <div className="side side-content-center">
            <div>
              <h2>About Me: Jerry Cloud - Empowering the Cloud for Innovation and Efficiency</h2>
              <p>
                {isMobileScreen && (
                  <img
                    src="https://d1sax8z0tfd9ab.cloudfront.net/icons/illustration.png"
                    alt=""
                  />
                )}
                Welcome to jerrycloud.in! I'm Jerry, a passionate DevOps engineer with a relentless drive to optimize 
                and streamline cloud infrastructures for businesses. Currently, 
                I am contributing my expertise at IoTium Systems (Recognized as VIEW.INC). 
                As a dedicated professional in the field, I take great pride in helping organizations harness the full potential of 
                cloud technologies to achieve their goals.
              </p>
              <h3 className="test">Who Am I?</h3>
              <p className="test">
                At heart, I am a tech enthusiast with a profound love for cloud computing and automation. With hands-on experience, 
                I have honed my skills in various cutting-edge technologies that empower me to build, 
                deploy, and manage cloud architectures efficiently.
              </p>
              <h3 className="test">My Expertise:</h3>
              <p className="test">
                <b>AWS and GCP:</b> Adept in harnessing the full potential of Amazon Web Services and Google Cloud Platform, 
                I architect scalable solutions and optimize cost structures to cater to diverse business needs.
              </p>
              <p className="test">
                <b>Docker and Kubernetes:</b> Embracing containerization with Docker and Kubernetes, I enhance application scalability, portability, 
                and overall efficiency in cloud environments. 
                This includes Helm deployments, Kubernetes cluster management, Kubernetes networking, and Swarm management with Docker.
              </p>
              <p className="test">
                <b>CI/CD with CodePipeline and Jenkins:</b> With a focus on
                continuous integration and continuous deployment, I automate
                software delivery pipelines, ensuring rapid and reliable
                application deployment.
              </p>
              <p className="test">
                <b>Terraform and Ansible:</b> Proficient in creating and managing infrastructure components as code, utilizing Terraform and 
                Ansible to deploy complex cloud environments securely and efficiently. 
                I maintain Terraform and Ansible scripts up to date with the latest releases to ensure robust infrastructure management.
              </p>
              <p className="test">
                <b>API Management with Apigee:</b> Experienced with Apigee for efficient API management and integrations, ensuring seamless 
                communication and data flow between services.
              </p>
              <p className="test">
                <b>Monitoring and Logging:</b> Implementing and managing monitoring stacks with Grafana, Prometheus, Loki, and ELK to ensure high system uptime and performance visibility.
              </p>
            </div>
          </div>
        </div>
        <div id="skills-section" ref={skillsRef} className="skills-section">
          <Skills />
        </div>
        <div id="experience-section" ref={experienceRef}>
          <Experience />
        </div>
        <div id="projects-section" ref={contactRef}>
          <Projects />
        </div>
        <div id="contact-section" ref={contactRef}>
          <ContactForm />
        </div>
      </div>
    </>
  );
}
