import React, { useState, useEffect } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { motion } from "framer-motion";
import "react-vertical-timeline-component/style.min.css";
import './resume.css';

const styles = {
  sectionSubText: "text-gray-600",
  sectionHeadText: "text-3xl font-bold text-gray-900",
};

const experiences = [
  {
    date: "August 2024 - Present",
    iconBg: "#1d1836",
    title: "GCP - APIGEE Engineer",
    company_name: "Ford Motor Comapany",
    points: [
      "Design and Implement API Proxies using Apigee Edge to enforce security, mediation, and protocol translation",
      "Monitor and Optimize API Performance using Apigee Analytics and GCP’s Stackdriver.",
      "Ensure API Security and Compliance by implementing OAuth 2.0, API keys, and JWT validation in Apigee.",
      "Manage Apigee environments and organizations within GCP ensuring high availability and scalability.",
      "Migrate Legacy APIs to Apigee Edge ensuring backward compatibility and minimal downtime.",
      "Manage Infrastructure as Code (IaC) using Terraform to provision and maintain Apigee resources and associated GCP services.",
      "Script Python/bash/Golang Scripts to automate processes",
      "Troubleshoot API Issues using Apigee Debugging tools and GCP logging services.",
    ],
  },
  {
    date: "March 2024 - August 2024",
    iconBg: "#1d1836",
    title: "DevOps Engineer",
    company_name: "Iotium Systems",
    points: [
      "Maintain Kubernetes Orchestrator using HELM",
      "Migrate Compose to Helm",
      "Ensure CloudPlatform Security Vulnerabilities are attended using Cloudsploit",
      "Manage Terraform and Ansible Scripts up to date depending upon the latest releases",
      "Automation of Production Pipelines",
      "Ensure 100% Uptime",
      "Script Python/bash Scripts to automate processes",
      "Create Groovy Scripts for Jenkins Jobs",
    ],
  },
  {
    date: "September 2023 - March 2024",
    iconBg: "#1d1836",
    title: "DevOps Engineer",
    company_name: "BetaSource.Tech",
    points: [
      "Worked with Kubernetes",
      "Build and manage the infrastructure required for the business",
      "Automate and improve the development and deployment process for releases",
      "Establish monitoring systems to improve visibility on infrastructure performance",
    ],
  },
  {
    date: "August 2022 - September 2023",
    iconBg: "#1d1836",
    title: "DevOps Engineer",
    company_name: "Pixel & Mortar",
    points: [
      "Build and manage the infrastructure required for the business",
      "Automate and improve the development and deployment process for releases",
      "Establish monitoring systems to improve visibility on infrastructure performance",
      "Ensure uptime for all critical infrastructure",
      "Create an efficient architecture that increases efficiency and reduces running costs",
    ],
  },
  {
    date: "March 2020 - August 2022",
    iconBg: "#1d1836",
    title: "AWS Engineer",
    company_name: "ST.Pauls Educational Trust",
    points: [
      "Worked as system operations and maintenance maintaining the software and hardware of computer resources",
      "In charge of the computer lab maintaining data backup and provisioning",
      "Setup and maintain internet connection over the network",
      "Installed and maintained various standard software as per requirements",
      "Installing patches and antivirus as per industry standards",
      "Regularly check the performance of servers and other systems to ensure they are running efficiently",
      "Apply updates and patches to operating systems, software, and hardware to ensure security and performance",
      "Assist with the setup, configuration, and maintenance of hardware such as servers, desktops, and laptops",
    ],
  },
];

const textVariant = () => {
  // Your textVariant implementation
};
const ExperienceCard = ({ experience }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check initial window size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element"
      contentStyle={{
        background: experience.iconBg,
        color: "#fff",
      }}
      contentArrowStyle={{ borderRight: "7px solid #232631" }}
      date={<span style={{ color: isMobile ? 'white' : 'black', fontWeight: 'bold' }}>{experience.date}</span>}
      iconStyle={{ background: experience.iconBg, color: '#fff' }}
    >
      <div>
        <h3 className='text-white text-[24px] font-bold'>{experience.title}</h3>
        <p
          className='text-secondary text-[16px] font-semibold'
          style={{ margin: 0 }}
        >
          {experience.company_name}
        </p>
      </div>

      <ul className='mt-5 list-disc ml-5 space-y-2'>
        {experience.points.map((point, index) => (
          <li
            key={`experience-point-${index}`}
            className='text-white-100 text-[14px] pl-1 tracking-wider'
          >
            {point}
          </li>
        ))}
      </ul>
    </VerticalTimelineElement>
  );
};

const Experience = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <h2 className={`${styles.sectionHeadText} text-center`}>
          Work Experience.
        </h2>
      </motion.div>

      <div className='mt-20 flex flex-col'>
        <VerticalTimeline>
          {experiences.map((experience, index) => (
            <ExperienceCard
              key={`experience-${index}`}
              experience={experience}
            />
          ))}
        </VerticalTimeline>
      </div>
    </>
  );
};

export default Experience;