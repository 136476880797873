export const scrollToHome = () => {
  const homeSection = document.getElementById("home-section");
  if (homeSection) {
    const scrollOffset = 0; // Adjust this value as needed
    const scrollToOptions = {
      behavior: "smooth",
      top: homeSection.getBoundingClientRect().top + window.scrollY + scrollOffset,
    };
    window.scrollTo(scrollToOptions);
  }
};

export const scrollToContact = () => {
  const contactSection = document.getElementById("contact-section");
  if (contactSection) {
    const scrollOffset = -200; // Adjust this value as needed
    const scrollToOptions = {
      behavior: "smooth",
      top: contactSection.getBoundingClientRect().top + window.scrollY + scrollOffset,
    };
    window.scrollTo(scrollToOptions);
  }
};

export const scrollToExperience = () => {
  const experienceSection = document.getElementById("experience-section");
  if (experienceSection) {
    const scrollOffset = -200; // Adjust this value as needed
    const scrollToOptions = {
      behavior: "smooth",
      top: experienceSection.getBoundingClientRect().top + window.scrollY + scrollOffset,
    };
    window.scrollTo(scrollToOptions);
  }
};

export const scrollToprojects = () => {
  const projectsSection = document.getElementById("projects-section");
  if (projectsSection) {
    const scrollOffset = -200; // Adjust this value as needed
    const scrollToOptions = {
      behavior: "smooth",
      top: projectsSection.getBoundingClientRect().top + window.scrollY + scrollOffset,
    };
    window.scrollTo(scrollToOptions);
  }
};

export const scrollToSkills = () => {
  const skillsSection = document.getElementById("skills-section");
  if (skillsSection) {
    const scrollOffset = -200; // Adjust this value as needed
    const scrollToOptions = {
      behavior: "smooth",
      top: skillsSection.getBoundingClientRect().top + window.scrollY + scrollOffset,
    };
    window.scrollTo(scrollToOptions);
  }
};
  