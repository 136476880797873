import React from 'react';
import './skills.css';
import { useInView } from 'react-intersection-observer';

const skillsData = [
  { name: 'AWS', icon: 'https://d1sax8z0tfd9ab.cloudfront.net/aws-icon-1024x1024-runl182z.png', level: 90 },
  { name: 'GCP', icon: 'https://d1sax8z0tfd9ab.cloudfront.net/icons/google-cloud.png', level: 50 },
  { name: 'Kubernetes', icon: 'https://d1sax8z0tfd9ab.cloudfront.net/icons/kubernetes-pngrepo-com.png', level: 75 },
  { name: 'Ansible', icon: 'https://d1sax8z0tfd9ab.cloudfront.net/image_processing20210621-14319-1vhnbng.png ', level: 60 },
  { name: 'Docker', icon: 'https://cdn-icons-png.flaticon.com/512/919/919853.png', level: 80 },
  { name: 'Terraform', icon: 'https://d1sax8z0tfd9ab.cloudfront.net/icons/28ec7440a57536eebad2931517aa1cce.png', level: 90 },
  { name: 'Jenkins', icon: 'https://d1sax8z0tfd9ab.cloudfront.net/Jenkins-Symbol.png ', level: 85 },
  { name: 'Apigee', icon: 'https://d1sax8z0tfd9ab.cloudfront.net/icons/OIP.jpg', level: 55 },
  { name: 'Python', icon: 'https://cdn-icons-png.flaticon.com/512/5968/5968350.png', level: 50 },
  { name: 'React JS', icon: 'https://cdn-icons-png.flaticon.com/512/919/919851.png', level: 40 },
  // Add more skills here
];

const Skills = () => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.3,
    });
  
    return (
      <div className={`skills-container ${inView ? 'animate' : ''}`} ref={ref}>
      <h1 className="skills-title">My Skills</h1>
      <div className="skills-list">
        {skillsData.map((skill, index) => (
          <div className="skill-card" key={index}>
            <div className="skill-info">
              <div className="skill-icon">
                <img
                  src={`${skill.icon}`}
                  alt={`${skill.name} Icon`}
                  className="icon-image"
                />
              </div>
              <h2 className="skill-name">{skill.name}</h2>
              <div className="skill-progress">
                <div
                  className="skill-progress-bar"
                  style={{ width: `${skill.level}%` }}
                ></div>
              </div>
              <p className="skill-level">Level: {skill.level}%</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
