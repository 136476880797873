// Projects.js

import React from "react";
import "./projects.css"; // Import your CSS file for styling

const projects = [
  {
    title: "Kubernetes Deployment",
    description:
      "Deployed microservices architecture on Kubernetes clusters. Implemented Helm charts for managing applications and services.",
    imageUrl:
      "https://d1sax8z0tfd9ab.cloudfront.net/icons/kubernetes-pngrepo-com.png", // Replace with your actual project image URL
    tags: ["Kubernetes", "Microservices", "Helm"],
    githubUrl: "https://github.com/2402199",
    demoUrl: "https://blog.jerrycloud.in",
  },
  {
    title: "Dockerized Application",
    description:
      "Containerized an application using Docker, optimizing development, testing, and deployment workflows.",
    imageUrl:
      "https://cdn-icons-png.flaticon.com/512/919/919853.png", // Replace with your actual project image URL
    tags: ["Docker", "Containerization"],
    githubUrl: "https://github.com/2402199",
    demoUrl: "https://blog.jerrycloud.in",
  },
  {
    title: "Infrastructure as Code with Terraform",
    description:
      "Managed cloud infrastructure using Terraform, provisioning and managing resources on AWS and GCP.",
    imageUrl:
      "https://d1sax8z0tfd9ab.cloudfront.net/icons/28ec7440a57536eebad2931517aa1cce.png", // Replace with your actual project image URL
    tags: ["Terraform", "AWS", "GCP"],
    githubUrl: "https://github.com/2402199",
    demoUrl: "https://blog.jerrycloud.in",
  },
  {
    title: "CI/CD Pipeline with Jenkins",
    description:
      "Implemented continuous integration and deployment pipelines with Jenkins, automating build, test, and deployment processes.",
    imageUrl:
      "https://d1sax8z0tfd9ab.cloudfront.net/Jenkins-Symbol.png", // Replace with your actual project image URL
    tags: ["Jenkins", "CI/CD", "Automation"],
    githubUrl: "https://github.com/2402199",
    demoUrl: "https://blog.jerrycloud.in",
  },
  {
    title: "Python Script for EC2 and IAM in AWS",
    description:
      "Automated creation of EC2 instances and corresponding IAM roles using Python scripting. Managed AWS resources programmatically.",
    imageUrl:
      "https://cdn-icons-png.flaticon.com/512/5968/5968350.png", // Replace with your actual project image URL
    tags: ["Python", "AWS", "Automation", "IAM", "EC2"],
    githubUrl: "https://github.com/2402199/aws-python-script",
    demoUrl: "https://blog.jerrycloud.in",
  },
  {
    title: "Apigee API Management: Contact Form Integration",
    description:
      "Integrated Apigee API management with a website contact form. Managed API requests and responses to trigger emails to both sender and receiver.",
    imageUrl:
      "https://d1sax8z0tfd9ab.cloudfront.net/icons/OIP.jpg", // Replace with your actual project image URL
    tags: ["Apigee", "API Management", "Integration"],
    githubUrl: "https://github.com/2402199/apigee-contact-form",
    demoUrl: "https://blog.jerrycloud.in",
  },
  // Add more projects as needed
];

const Projects = () => {
  return (
    <div className="projects-container">
      <h2 className="projects-title">Projects</h2>
      <div className="projects-list">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <img src={project.imageUrl} alt={project.title} className="project-image" />
            <div className="project-info">
              <h3 className="project-name">{project.title}</h3>
              <p className="project-description">{project.description}</p>
              <div className="project-tags">
                {project.tags.map((tag, idx) => (
                  <span key={idx} className="tag">
                    {tag}
                  </span>
                ))}
              </div>
              <div className="project-links">
                <a href={project.githubUrl} className="project-link" target="_blank" rel="noopener noreferrer">
                  GitHub
                </a>
                <a href={project.demoUrl} className="project-link" target="_blank" rel="noopener noreferrer">
                  Demo
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
